<template>
  <VDialog class="gap-2" width="550px">
    <div class="flex flex-col gap-4 m-4">
      <div class="border-b pb-2">
        <VHeading level="4" medium class="text-center" >
          {{ localize('create_dialog.title') }}
        </VHeading>
      </div>
      <VParagraph>{{ localize('create_dialog.description') }}</VParagraph>
      <div class="flex flex-col gap-2 border border-gray-300  px-4 py-1 max-h-[60vh] overflow-y-auto rounded-md avv-bg">
        <div v-for="clause in clauses" :key="clause.id" class="flex justify-start items-center gap-2">
          <div class="flex items-center gap-2">
            <VCheckbox v-model="clause.checked" :label="clause.name"/>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-8 mt-4">
      <VButton variant="text" @click="emit('close')">
        {{ localize.global('general.cancel') }}
      </VButton>
      <VButton variant="text" @click="create">
        {{ localize.global('general.create') }}
      </VButton>
    </div>
  </VDialog>
</template>
<script lang='ts' setup>
import VButton from '@component-library/buttons/VButton.vue';
import VDialog from '@component-library/dialogs/VDialog.vue';
import VCheckbox from '@component-library/inputs/VCheckbox.vue';
import VHeading from '@component-library/labels/VHeading.vue';
import VParagraph from '@component-library/labels/VParagraph.vue';
import { useDialog } from '@component-utils/dialogs';
import { useLocalize } from '@component-utils/localization';
import { useErrorToast } from '@component-utils/toasts';
import { ref } from 'vue';
import SummaryPreview from './SummaryPreview.vue';
import SummaryDocumentsApi from '~/api/ContractSummaries/SummaryDocumentsApi';

type TaggedClause = {id: string, name: string, checked?:boolean}[]
type HeaderType = 'Clause' | 'Standard' | 'Updated' | 'Approval status' | 'Approver'

const localize = useLocalize('summary')
const props = defineProps<{
  clauses: TaggedClause
}>()
const emit = defineEmits<{
  close: []
}>()
const clauses = ref(props.clauses)
const documentId = AvvStore.state.document_id

const create = async () => {
  const selectedClausesIds = clauses.value.filter(clause => clause.checked).map(clause => clause.id)
  try{
    const {clauses, summary_name, parties, entries, headers} = await SummaryDocumentsApi.get<{clauses: Backend.Models.SummaryClause[], summary_name: string, parties: Backend.Models.Participant[], entries:{name:string, value: string}[], headers: HeaderType[]}>({id: documentId, query:{clause_ids: selectedClausesIds}})
    useDialog(SummaryPreview, {clauses: clauses, summaryName: summary_name, parties: parties, attributes: entries, headers: headers})
  }catch(e) {
    useErrorToast(e)
  }
 emit('close')
}
</script>