<template>
  <VDialog width="900px" close-via-button>
    <div class="-m-5 pt-8 modern-color-theme bg-primary-150 rounded-lg">
      <div class="bg-white flex justify-between my-4 px-8 py-2 items-center">
        <VSpan>{{ summaryName }}</VSpan>
        <VDropdown :items="exportOptions"><VButton size="sm" variant="outlined"><VIcon size="sm" name="Outline/arrow-down-tray"/>{{ localize.global('general.export') }}</VButton></VDropdown>
      </div>
      <div class="bg-white rounded-lg my-4 mx-4 p-2 max-h-[550px] overflow-y-scroll">
        <div class="my-4 mx-4">
          <VHeading level="6">{{ localize('basic_data') }}</VHeading>
        </div>
        <div class="grid grid-cols-1 divide-y divide-neutral-450 border border-neutral-450 w-2/3 m-4">
          <div v-for="attribute in attributes" :key="attribute.name">
            <div class="grid grid-cols-2 divide-x divide-neutral-450">
              <div class="text-gray-600 p-3 flex items-center">
                <VSpan color="neutral/900">{{ attribute.name }}</VSpan>
              </div>
              <div class="text-gray-600 p-3 flex items-center">
                <VSpan>{{ attribute.value }}</VSpan>
              </div>
            </div>
          </div>
        </div>
        <div class="m-4">
          <VHeading level="6">{{ localize('deviation_analysis') }}</VHeading>
        </div>
        <div class="overflow-x-auto m-4">
          <table class="w-full border border-neutral-450">
            <thead>
              <tr>
                <th v-for="header in headers" :key="header" class="border border-neutral-450 p-2 text-left">
                  <VSpan color="neutral/900">{{ header }}</VSpan>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="clause in clauses" :key="clause.id">
                <td v-for="header in headers" :key="header"class="border border-neutral-450 p-2">
                  <div class="avv-editor">
                    <div class="avv-tracking-changes" v-html="getClauseValue(clause, header)"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </VDialog>
</template>
<script lang='ts' setup>
import VButton from '@component-library/buttons/VButton.vue';
import VDropdown from '@component-library/buttons/VDropdown.vue';
import VDialog from '@component-library/dialogs/VDialog.vue';
import VHeading from '@component-library/labels/VHeading.vue';
import VIcon from '@component-library/labels/VIcon.vue';
import VSpan from '@component-library/labels/VSpan.vue';
import { useLocalize } from '@component-utils/localization';
import type { V } from '@component-utils/types';

type HeaderType = 'Clause' | 'Standard' | 'Updated' | 'Approval status' | 'Approver'
type SummaryClause = Backend.Models.SummaryClause

type HeaderMappingType = {
  [K in HeaderType]: keyof SummaryClause;
}
const props = defineProps<{
  clauses: SummaryClause[]
  summaryName: string
  attributes: {name: string, value: string}[]
  parties: Backend.Models.Participant[]
  headers: HeaderType[]
}>()

const localize = useLocalize('summary')

const headerMapping: HeaderMappingType = {
  'Clause': 'name',
  'Standard': 'standard_content',
  'Updated': 'updated_content',
  'Approval status': 'approval',
  'Approver': 'approver'
}

const exportOptions: V.ContextMenu.Item<unknown>[] = [
  { type: 'button', label: localize.global('integrations.dialogs.synchronizations.options.format_docx.title'), action: () => {} },
  { type: 'button', label: localize.global('integrations.dialogs.synchronizations.options.format_pdf.title'), action: () => {} },
]

const getClauseValue = (clause: SummaryClause, header: HeaderType): string => {
  const property = headerMapping[header]
  return clause[property]
}
</script>
